import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { publish, publishAndCall } from "../websockets/Websockets";

import { useDataContext } from "../context/DataProvider";
import { useWebsocketContext } from "../context/WebsocketProvider";
import { useDateAndPageContext } from "../context/DateProvider";

function Home() {
  const navigate = useNavigate();
  const { numPages, loading, fetchData } = useDataContext();
  const { client, loadingWeb } = useWebsocketContext();
  const { date, setDate, page, setPage } = useDateAndPageContext();

  const actions = [
    { text: "Change Horse", path: "/change/horse" },
    { text: "Change Saddle", path: "/change/saddle" },
    { text: "Horse In, Has Special Feed, Has Blanket", path: "/change/flags" },
    { text: "Set Rider Absent", path: "/change/absences" },
    { text: "Add Rider", path: "/add/rider" },
    { text: "Change Horse/Rider Note", path: "/change/note" },
    { text: "Change Lesson Note", path: "/change/lessonNote" },
    { text: "View Saddle List", path: "/view/saddles" },
    // { text: "Toggle Board Mode", path: "/change/rider" },
  ];

  useEffect(() => {
    // Set an interval to fetch the data every 60 seconds
    const intervalId = setInterval(fetchData, 60000);

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (loading || loadingWeb) {
    return <div>Loading...</div>;
  }

  console.log(numPages);
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
      padding={"1em"}
    >
      <Grid
        item
        xs={12}
        container
        justifyContent={"center"}
        marginBottom={"1em"}
        columnSpacing={"1em"}
      >
        <Grid
          item
          xs={8}
          container
          justifyContent={"center"}
          marginBottom={"1em"}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={date}
              onChange={(value, context) => {
                setDate(value ?? dayjs());

                publishAndCall(
                  client,
                  {
                    type: "dateChange",
                    date: value?.format("YYYY-MM-DD"),
                  },
                  {
                    path: "/board/control",
                    body: {
                      boardDate: value
                        ? value.format("YYYY-MM-DD")
                        : dayjs().format("YYYY-MM-DD"),
                      page: 1,
                    },
                  }
                );
              }}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={4}
          container
          justifyContent={"center"}
          marginBottom={"1em"}
        >
          <TextField
            label="Page"
            select
            aria-label="Page"
            onChange={(e: any) => {
              setPage(e.target.value);

              publishAndCall(
                client,
                {
                  type: "pageChange",
                  page: e.target.value,
                },
                {
                  path: "/board/control",
                  body: {
                    boardDate: date.format("YYYY-MM-DD"),
                    page: Number(e.target.value),
                  },
                }
              );
            }}
            value={page}
            fullWidth
          >
            {[...Array(numPages && numPages > 1 ? numPages : 2)].map(
              (_, index) => {
                if (index == 0) {
                  return null;
                }

                return (
                  <MenuItem value={index.toString()} key={index}>
                    {index.toString()}
                  </MenuItem>
                );
              }
            )}
          </TextField>
        </Grid>
      </Grid>
      {actions.map((action, index) => {
        return (
          <Grid
            item
            xs={12}
            container
            justifyContent={"center"}
            marginBottom={"1em"}
            key={index}
          >
            <Button
              variant="outlined"
              color="success"
              style={{
                minWidth: "95vw",
                fontSize: "xx-large",
                minHeight: "9vh",
              }}
              onClick={() => {
                navigate(
                  { pathname: action.path },
                  {
                    state: {
                      date: date?.format("YYYY-MM-DD"),
                    },
                  }
                );
              }}
            >
              {action.text}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default Home;
